import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store'
import type { Observable } from 'rxjs'
import { filter, finalize, map, switchMap, tap } from 'rxjs/operators'
import { StateSite } from '@modules/site/state/reducers/site.reducer'
import { selectSiteLoading } from '@modules/site/state/selectors/site.selectors'
import { loadingSite, setSite } from '@modules/site/state/actions/site.actions'
import { ISite } from '@modules/site/interfaces/site'
import { SiteResource } from '@modules/site/site.resource'
import * as SiteActions from '../actions/site.actions'

@Injectable()
export class SiteEffects {
  loadSite$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SiteActions.loadSite),
      concatLatestFrom(() => this._store.select(selectSiteLoading)),
      filter(([, isLoading]) => {
        return !isLoading
      }),
      tap(() => {
        this._store.dispatch(loadingSite({ loading: true }))
      }),
      switchMap((): Observable<any> => {
        return this._siteResource.query().pipe(
          map((site: ISite[]) => {
            return setSite({ site: site })
          }),
          finalize(() => {
            this._store.dispatch(loadingSite({ loading: false }))
          })
        )
      })
    )
  })

  constructor(
    private actions$: Actions,
    private _siteResource: SiteResource,
    private _store: Store<{ site: StateSite }>
  ) {}
}
