import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('@shared/templates/base/base.module').then(m => m.BaseModule),
  },
  {
    path: '',
    loadChildren: () => import('@shared/templates/unauth/unauth.module').then(m => m.UnauthModule),
  },
  {
    path: '**',
    redirectTo: '',
  },
];
