import {
  ApplicationConfig,
  DEFAULT_CURRENCY_CODE,
  importProvidersFrom,
  inject,
  LOCALE_ID,
  provideAppInitializer,
  provideZoneChangeDetection
} from '@angular/core'
import { provideRouter, withInMemoryScrolling, withRouterConfig } from '@angular/router'

import { routes } from './app.routes'
import { LetDirective, PushPipe } from '@ngrx/component'
import { ModalModule } from '@molecule/modal/modal.module'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { BrowserModule } from '@angular/platform-browser'
import { ResourceModule } from '@ngx-resource/handler-ngx-http'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { EffectsModule } from '@ngrx/effects'
import { SiteEffects } from '@modules/site/state/effects/site.effect'
import { AppEffects } from '@shared/state/effets/app.effect'
import { CreditSettingEffects } from '@modules/credit/state/effets/credit-setting.effect'
import { provideToastr } from 'ngx-toastr'
import { CalendarModule, DateAdapter } from 'angular-calendar'
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns'
import { provideFlatpickrDefaults } from 'angularx-flatpickr'
import { StoreModule } from '@ngrx/store'
import { collaboratorReducer } from '@modules/collaborator/state/reducers/collaborator.reducer'
import { appReducer } from '@shared/state/reducers/app.reducer'
import { withdrawalReducer } from '@modules/withdrawal/state/reducers/withdrawal.reducer'
import { siteReducer } from '@modules/site/state/reducers/site.reducer'
import { periodReducer } from '@modules/period/state/reducers/period.reducer'
import { creditReducer } from '@modules/credit/state/reducers/credit.reducer'
import { creditSettingsReducer } from '@modules/credit/state/reducers/credit-settings.reducer'
import { groupReducer } from '@modules/group/state/reducers/group.reducer'
import { deductionReducer } from '@modules/deduction/state/reducers/deduction.reducer'
import { companyReducer } from '@modules/company/state/reducers/company.reducer'
import { variableIncomeBatchReducer } from '@modules/variable-income/state/reducers/variable-income-batch.reducer'
import { paymentCollectionReducer } from '@modules/payment-collection/state/reducers/payment-collection.reducer'
import { adminReducer } from '@modules/configuration/state/reducers/admins.reducer'
import { shopReducer } from '@modules/shop/state/reducers/shop.reducer'
import { environment } from '@environments/environment'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { ListModule } from '@services/list/list.module'
import { IdemPotencyService } from '@services/IdemPotency.service'
import { BaseFormBuilder } from '@services/base-formbuilder'
import { CompanyGuard } from '@shared/guards/company.guard'
import { SessionService } from '@services/session.service'
import { ToastrService } from '@services/toastr.service'
import { BaseResource } from '@shared/resources/base.resource'
import { AuthResource } from '@shared/resources/auth.resource'
import { SiteService } from '@modules/site/site.service'
import { CookieService } from 'ngx-cookie-service'
import { ModalService } from '@molecule/modal/services/modal.service'
import { InitService } from '@services/init.service'
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
import { registerLocaleData } from '@angular/common'
import localeES from '@angular/common/locales/es-MX'

if (localeES[1]) {
  localeES[1] = [['AM', 'PM'], undefined, undefined]
}
registerLocaleData(localeES)
export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes,
      withRouterConfig({
        onSameUrlNavigation: 'reload',
      }),
      withInMemoryScrolling({
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'top',
      }),
    ),
    importProvidersFrom([
      LetDirective,
      PushPipe,
      ModalModule,
      BrowserAnimationsModule,
      BrowserModule,
      ResourceModule.forRoot(),
      FormsModule,
      ReactiveFormsModule,
      EffectsModule.forRoot([SiteEffects, AppEffects, CreditSettingEffects]),
      CalendarModule.forRoot({
        provide: DateAdapter,
        useFactory: adapterFactory,
      }),
      StoreModule.forRoot({
        collaborator: collaboratorReducer,
        app: appReducer,
        withdrawal: withdrawalReducer,
        site: siteReducer,
        period: periodReducer,
        credit: creditReducer,
        creditSettings: creditSettingsReducer,
        group: groupReducer,
        deduction: deductionReducer,
        company: companyReducer,
        variableIncomeBatch: variableIncomeBatchReducer,
        paymentCollection: paymentCollectionReducer,
        admin: adminReducer,
        shop: shopReducer,
      }, {
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
        },
      }),
      !environment.production
        ? StoreDevtoolsModule.instrument({
          maxAge: 25,
          logOnly: environment.production,
          autoPause: true, // Pauses recording actions and state changes when the extension window is not open
        })
        : [],
      StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
      ListModule
    ]),

    IdemPotencyService,
    BaseFormBuilder,
    CompanyGuard,
    SessionService,
    ToastrService,
    BaseResource,
    AuthResource,
    SiteService,
    CookieService,
    ModalService,
    provideFlatpickrDefaults(),
    provideToastr(),
    { provide: LOCALE_ID, useValue: 'es-MX' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'MXN' },
    /*{
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },*/
    /*{
      provide: Sentry.TraceService,
      deps: [Router],
    },*/
    provideAppInitializer(() => {
      const initializerFn = ((_initService: InitService) => () => _initService.init())(inject(InitService))
      return initializerFn()
    }),
    //TODO:  check Sentry.TraceService
    /*provideAppInitializer(() => {
        const initializerFn = (() => () => { })(inject(Sentry.TraceService));
    return initializerFn();
  }),*/
    provideHttpClient(withInterceptorsFromDi()),
  ]
}
